<template>
  <!--<div class="hello">
    <h1>{{ msg }}</h1>
  </div>-->
  <div class="cubetimer">
    <div class="controls">
      <div class="timerdisplay">
        {{ display }}
      </div>
      <div class="timerbutton">
        <div class="button" @click="startstop()">{{ running ? 'Stop' : 'Start'}}</div>
      </div>
    </div>
    <div class="log">
      <div class="statistics">
        <div>Bestzeit: {{ best }}</div>
        <div>Durchschnitt: {{ mean }}</div>
      </div>
      <div class="timerlist" v-if="log.length">
        <table>
          <thead>
            <td>Versuch</td>
            <!--<td>Wert</td>-->
            <td>Zeit</td>
          </thead>
          <tbody>
            <tr v-for="item in log" :key="item.key">
              <td>{{ item.key }}</td>
              <!--<td>{{ item.value }}</td>-->
              <td>{{ item.display }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <GlobalEvents @keyup.space="startstop()"/>
</template>

<script>
import { GlobalEvents } from 'vue-global-events';

export default {
  name: 'CubeTimer',
  components: {
    GlobalEvents
  },
  props: {
  },
  data(){
    return {
      running: false,
      display: '0:00.00',
      start: null,
      runcounter: 0,
      interval: null,
      best: '-',
      mean: '-',
      log: [
        // {'key': 1,'display': 'blubb'},
        // {'key': 2,'display': 'blabb'},
        // {'key': 3,'display': 'blebb'},
        // {'key': 4,'display': 'blobb'},
        // {'key': 1,'display': 'blubb'},
        // {'key': 2,'display': 'blabb'},
        // {'key': 3,'display': 'blebb'},
        // {'key': 4,'display': 'blobb'},
        // {'key': 1,'display': 'blubb'},
        // {'key': 2,'display': 'blabb'},
        // {'key': 3,'display': 'blebb'},
        // {'key': 4,'display': 'blobb'},
        // {'key': 1,'display': 'blubb'},
        // {'key': 2,'display': 'blabb'},
        // {'key': 3,'display': 'blebb'},
        // {'key': 4,'display': 'blobb'},
        ],
      }
    },
  methods: {
    startstop(){
      if (!this.running){
        // timer starten
        this.runcounter++;
        this.start = Date.now();
        this.interval = setInterval(this.advanceTimer, 66);
        this.running = true;
      } else {
        // timer stoppen
        var elapsed = Math.floor((Date.now() - this.start) / 10);
        clearInterval(this.interval);
        this.display = this.parseCounter(elapsed);
        this.log.push({
          'key': this.runcounter,
          'value': elapsed,
          'display': this.display
        });
        this.calcStats();
        this.running = false;
      }
    },
    advanceTimer(){
      this.counter++;
      var elapsed = Math.floor((Date.now() - this.start) / 10);
      this.display = this.parseCounter(elapsed);
    },
    calcStats(){
      // Durschnitt
      var total = 0;
      this.log.forEach( (item) => {
        total += item.value;
      });
      var mean = Math.floor(total / this.log.length);
      this.mean = this.parseCounter(mean);
      // Bestzeit
      var best = 10000000000000;
      this.log.forEach( (item) => {
        best = (item.value < best) ? item.value : best;
      });
      this.best = this.parseCounter(best);
    },
    parseCounter(counter){
      // werte
      var dec = counter % 100;
      var sec = Math.floor(counter/100) % 60;
      var min = Math.floor(counter/100/60);
      // hundertstel
      if (dec < 10){
        dec = '0' + dec;
      }
      // sekunden
      if (sec < 10){
        sec = '0' + sec;
      }
      return min + ':' + sec + '.' + dec;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../less/defaults";
@import "../less/fonts";

.cubetimer{
  max-height: 100%;
  display: flex;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
  gap: 1em;
  .controls{
    // background-color: blue;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
  }
  .log{
    flex: 1;
    overflow-y: auto;
    position: relative;
    .statistics{
      position: sticky;
      top: 0;
      background-color: @bgcolor;
      padding-bottom: 1em;
    }
    .statistics,
    .timerlist{
      text-align: center;
      table{
        display: inline-block;
      }
    }
  }
}
.timerdisplay{
  font-family: 'Red Hat Mono';
  font-weight: 500;
  font-size: 3em;
  color: black;
  background-color: #ddd;
  background: linear-gradient(170deg, white, rgb(137, 137, 137));
  border: 4px solid rgb(125, 125, 125);
  // margin: 1rem;
  border-radius: 12px;
  padding: 1rem 5rem;
}
.timerlist{
  text-align: left;
  table{
    border-collapse: collapse;
    thead{
      position: sticky;
      top: 4em;
      background-color: @bgcolor;
    }
    tr{
      border-top: 1px solid rgb(122, 122, 122);
    }
    td{
      text-align: right;
      padding: 0.25em 1em;
    }
  }
}
.statistics{
  text-align: right;
  font-size: 1.25em;
}
.button{
  font-size: 2em;
  display: inline-block;
  padding: 0.5em 2em;
  border: 2px solid @maincolor;
  border-radius: 4px;
  color: white;
  background-color: @maincolor;
  cursor: pointer;
  &:hover{
    background-color: white;
    color: @maincolor;
  }
}
</style>
