<template>
  <div class="wrapper">
    <CubeTimer/>
  </div>
</template>

<script>
import CubeTimer from './components/CubeTimer.vue'

export default {
  name: 'App',
  components: {
    CubeTimer
  }
}
</script>

<style lang="less">
@import "./less/defaults";
body{
  background-color: @bgcolor;
  margin:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  padding: 2em;
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.wrapper{
  max-height: 100%;
}
</style>
